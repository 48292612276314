<template>
  <div class="querycommunity">
    <LiefengContent>
      <template v-slot:title>{{ `机构菜单栏目发布统计` }}</template>
      <template v-slot:toolsbarRight>
        <Form :label-colon="true" :inline="true" class="search">
          <Button type="primary" @click="exportData" style="margin-right: 10px">导出</Button>
        </Form>
      </template>

      <template v-slot:contentArea>
        <LiefengTable
            :tableData="pageData.tableData"
            :talbeColumns="pageData.tableColumns"
            :loading="loading"
            :fixTable="true"
            :hidePage="true"
            @tableSelect="tableSelect"
        ></LiefengTable>
      </template>
    </LiefengContent>
  </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import LiefengCascaderCity from "@/components/LiefengCascaderCity"
import LiefengTable from "@/components/LiefengTable"

export default {
  components: {
    LiefengContent,
    LiefengCascaderCity,
    LiefengTable
  },
  destroyed() {
    this.data = null
  },
  created() {
    this.search.orgCode = this.$route.query.orgCode;
    console.log(this.search.orgCode)
    this.request();
  },
  data() {
    return {
      loading:false,
      pageData: {
        totalSize:0,
        tableData: [],
        tableColumns: [
          {type: "selection",width: 60,align: "center"},
          {title: '维度名称', key: 'dimensionName'},
          {title: '机构名称', key: 'orgName'},
          {title: '一级导航', key: 'menuTopName'},
          {title: '二级导航', key: 'menuName'},
          {title: '发布信息数量', key: 'publishNum'},
          {title: '居民接受服务人次', key: 'uv'},
          {title: '居民接受服务人数', key: 'uvUser'},
          {title: '居民参与（互动）服务人次', key: 'pv'},
          {title: '居民参与（互动）服务人数', key: 'pvUser'},
        ],
      },
      search: {
        dimensionId: '',
        orgCode: ''
      }
    }
  },
  methods: {
    tableSelect(val){
      this.summaryDataList(val);
    },
    request() {
      this.loading = true;
      this.$get("/gateway/api/symanage/pc/orgColumnMenu/selectRecord", {
        orgCode: this.search.orgCode,
        dimensionId:'',
      })
      .then((res) => {
            if (res.code == 200) {
              this.pageData.tableData = res.dataList;
              this.pageData.tableData.forEach(d=>{
                d["_checked"] = true;
              })
              var length = this.pageData.tableData.length;
              this.pageData.totalSize = length;
              this.summaryDataList(res.dataList);
            } else {
              this.pageData.tableData = [];
              this.$Message.error({
                background: true,
                content: res.desc,
              });
            }
            this.loading = false;
          })
          .catch((err) => {
            this.$Message.error({
              background: true,
              content: "获取数据失败",
            });
          });
    },
    summaryDataList(list){
      var publishNum = 0;
      var uv = 0;
      var uvUser = 0;
      var pv = 0;
      var pvUser = 0;
      list.forEach(d=>{
        publishNum += d.publishNum;
        uv += d.uv;
        uvUser += d.uvUser;
        pv += d.pv;
        pvUser += d.pvUser;
      })
      var data = {
        "dimensionName":"合计",
        "publishNum":publishNum,
        "uv":uv,
        "uvUser":uvUser,
        "pv":pv,
        "pvUser":pvUser,
        "_disabled":true
      };
      var length = this.pageData.totalSize;
      console.log(data)
      // this.pageData.tableData[length] = data;
      this.$set(this.pageData.tableData, length, data);
    },
    // 导出
    exportData() {
      if (this.pageData.tableData.length == 0) {
        this.$Notice.error({
          title: '当前页面数据为空，不能导出',
        });
        return
      }
      this.$Modal.confirm({
        title: "温馨提示",
        content: `<p>您正在导出统计报表，是否继续？</p>`,
        onOk: () => {
          this.$Message.loading({
            content: "正在导出数据，请稍等...",
            duration: 0
          })
          let tHeader = [
            "维度名称",
            "机构名称",
            "一级导航",
            "二级导航",
            "发布信息数量",
            "居民接受服务人次",
            "居民接受服务人数",
            "居民参与（互动）服务人次",
            "居民参与（互动）服务人数",
          ];
          let filterVal = [
            "dimensionName",
            "orgName",
            "menuTopName",
            "menuName",
            'publishNum',
            "uv",
            "uvUser",
            "pv",
            "pvUser"
          ]
          let data = JSON.parse(JSON.stringify(this.pageData.tableData));
          this.$core.exportExcel(tHeader, filterVal, data, "数据报表");
          this.$Message.destroy();
        }
      });
    }
  }
}
</script>

<style scoped lang="less">
.querycommunity {
  /deep/ .table > .ivu-table > .ivu-table-tip > table > tbody > tr > td {
    height: calc(100vh - 150px);
    border-left: 1px solid #f8f8f9;
    border-bottom: 1px solid #f8f8f9;
    border-right: 1px solid #f8f8f9;
  }
}
</style>